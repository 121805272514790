import React from 'react'

import data from '../../content/index.yaml'

const Coop = () => {
  const isFullSize = typeof window !== 'undefined' ? matchMedia('(min-width: 950px)').matches : false
  const companies = data.coop.companies
  const companiesChunks = () => {
    if (isFullSize) {
      let firstDone = false
      let chunksArr = []
      let currentChunksArr = []
      let limit = () => firstDone ? 5 : 6

      companies.forEach(co => {
        if (currentChunksArr.length === limit()) {
          chunksArr.push(currentChunksArr)
          currentChunksArr = []
          if (chunksArr.length === 1) {
            firstDone = true
          }
        }

        currentChunksArr.push(co)
      })

      if (currentChunksArr.length) {
        chunksArr.push(currentChunksArr)
      }

      return chunksArr
    } else {
      return [companies]
    }
  }

  return (
    <section id="coop" className="c-coop">
      <h2 className="o-heading o-heading--with-bg">
        {data.coop.title}
      </h2>

      <div className="c-coop__wrapper">
      {companiesChunks().map((chunk, i) => (
        <div className="c-coop__loop o-wrapper" key={i}>
          {chunk.map(comp => (
            <div className="c-coop__item" key={comp.image.url}>
              <img src={comp.image.url} alt={comp.image.title} />
            </div>
          ))}
        </div>
      ))}
      </div>
    </section>
  )
}

export default Coop