import React from 'react'

import data from '../../content/index.yaml'

const About = () => {
  return (
    <section id="about" className="c-about">
      <h2 className="o-heading o-heading--with-bg">
        {data.about.title}
      </h2>

      <div className="c-about__blur">
        <div className="c-about__wrapper">
          <div className="c-about__content" dangerouslySetInnerHTML={{ __html: data.about.content }} />
          <div className="c-about__img">
            <img src="/o-mnie.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About