import React from 'react'

import Button from './button'

const Form = () => {
  return (
    <form className="c-form" action="php/sendmail.php" method="POST">
      <input className="c-form__input" name="name" type="text" placeholder="Imię:" required />
      <input className="c-form__input" name="secondname" type="text" placeholder="Nazwisko:" required />
      <input className="c-form__input" name="email" placeholder="Email:" required />
      <input type="hidden" name="cf-s" />
      <textarea className="c-form__textarea" name="message" rows="6" placeholder="W czym mogę pomóc?" />

      <Button nativeType="submit" name="submit" title="Wyślij" />
    </form>
  )
}

export default Form
