import React from 'react'

import Form from './form'

import ContactLogo from '../images/contact-logo.svg'

const Contact = () => {
  const urlParam = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : false)
  const emailParam = urlParam.get('mail')
  let hasMailMessage = false
  let formMessage = ''

  if (emailParam) {
    hasMailMessage = true
    formMessage = emailParam === 'error' ?
      'Coś się nie powiodło!' :
      'Dziękujemy za wiadomość!'
  }

  return (
    <section id="contact" className="c-contact">
      <h2 className="o-heading o-heading--with-bg">
        Kontakt
      </h2>

      <div className="c-contact__wrapper">
        <div className="c-contact__side-col c-contact__side-col--empty" />

        <div className="c-contact__form">
          <h3 className="o-heading o-heading--contact">Skontaktuj się ze mną</h3>
          {hasMailMessage && (
            <div className={`c-contact__form-message c-contact__form-message--${emailParam}`}>
              { formMessage }
            </div>
          )}
          <Form />
        </div>

        <div className="c-contact__side-col c-contact__side-col">
          <img className="c-contact__logo" src={ContactLogo} alt="" />
          <p>
            Katarzyna Kobiela
          </p>
          <p>
            <a className="c-contact__link" href="tel:692 290 288">692 290 288</a>
          </p>
          <p>
            <a className="c-contact__link" href="mailto:kasiakobiela.kk@gmail.com">kasiakobiela.kk@gmail.com</a>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Contact
