import React from 'react'

import InstagramLogo from '../images/instagram.svg'

class Instagram extends React.Component {
  state = {
    items: []
  }

  componentDidMount() {
    fetch('/instagram.json').then(res => res.json()).then(data => {
      this.setState({
        items: data
      })
    })
  }

  render() {
    const instagram = () => {
      if (!this.state.items.length) return false

      return (
        <section id="instagram" className="c-instagram">
          <h2 className="o-heading o-heading--with-bg">
            Instagram
          </h2>

          <div className="c-instagram__link">
            <img className="c-instagram__link-logo" src={InstagramLogo} alt="" />
            <a href="https://instagram.com/katarzynakobiela_stylist" className="c-instagram__link-item" target="_blank" ref="noreferrer">
              @katarzynakobiela_stylist
            </a>
          </div>

          <div className="c-instagram__wrapper">
            {this.state.items.map(item => (
              <div key={item.id} className="c-instagram__item" style={{backgroundImage: `url(${item.media_url})`}}>
                <div className="c-instagram__caption" dangerouslySetInnerHTML={{__html: item.caption}} />
              </div>
            ))}
          </div>
        </section>
      )
    }

    return instagram()
  }

}

export default Instagram
