import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from '../components/about'
import Offer from '../components/offer'
import Instagram from '../components/instagram'
import Contact from '../components/contact'
import Coop from '../components/coop'

import data from '../../content/index.yaml'

const IndexPage = () => (
  <Layout>
    <SEO title={data.title} />
    <About />
    <Offer />
    <Instagram />
    <Contact />
    <Coop />
  </Layout>
)

export default IndexPage
