import React from 'react'

import Button from './button'

import data from '../../content/index.yaml'

class Offer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalContent: null
    }
    this.hideContent = this.hideContent.bind(this)
    this.closeOutside = this.closeOutside.bind(this)
  }

  componentDidMount() {
    const btnItems = Array.from(document.querySelectorAll('.c-offer__item .c-button'))

    if (btnItems.length) {
      let btnsHeight = []

      btnItems.forEach(item => {
        const rect = item.getBoundingClientRect()

        btnsHeight.push(rect.height)
      })

      btnItems.forEach(item => {
        item.style.height = Math.max(...btnsHeight) + 'px'
      })
    }

    document.addEventListener('click', this.closeOutside)
  }

  closeOutside(e) {
    if (this.state.modalContent !== null) {
      if (!Array.from(e.target.classList).filter(c => c === 'c-offer__modal-text' || c === 'c-offer__item-img' || c === 'c-button').length) {
        this.hideContent()
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeOutside)
  }

  hideContent(e) {
    document.documentElement.style.overflow = 'auto';
    this.setState({
      modalContent: null
    })
  }

  showContent(index) {
    const clickedOffer = data.offer.content[index]
    if (window.matchMedia( "(max-width: 650px)" ).matches) {
      if (!clickedOffer.content) return
    }
    document.documentElement.style.overflow = 'hidden';
    this.setState({
      modalContent: clickedOffer
    })
    setTimeout(() => {
      const selector = document.querySelector('.c-offer__modal')
      if (selector) {
        selector.scrollTop = 0
      }
    }, 100)
  }

  render() {
    const { modalContent } = this.state
    return (
      <section id="offer" className="c-offer">
        <h2 className="o-heading o-heading--with-bg">
          {data.offer.title}
        </h2>

        <div className="c-offer__items">
          {data.offer.content.map((item, i) => (
            <div key={item.title} className="c-offer__item">
              <Button title={item.title} onClick={() => this.showContent(i)} />

              <div className={`c-offer__item-wrapper ${!item.content ? 'c-offer__item-wrapper--center' : ''} ${this.state.currentContent === item.title ? 'show' : ''}`}>
                <div className={`c-offer__item-content c-offer__item-content--${item.slug}`}>
                  <div className="c-offer__image-wrapper">
                    <img className="c-offer__item-img" src={item.image} alt="" onClick={() => this.showContent(i)} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={`c-offer__modal ${modalContent ? 'is-open' : ''}`}>
          {/* <button className="c-offer__close-modal" onClick={this.hideContent}>Zamknij</button> */}
          {modalContent && (
            <div className={`c-offer__modal-content`}>
              <img className="c-offer__modal-img" src={modalContent.image} alt="" />
              {modalContent.content && (
                <div
                  className="c-offer__modal-text"
                  dangerouslySetInnerHTML={{__html: modalContent.content}}
                />
              )}
            </div>
          )}
        </div>
      </section>
    )
  }
}

export default Offer
